import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export class AsideComponent extends PewComponent {
  init() {
    this.registerSummaryToggler();
  }

  registerSummaryToggler() {
    this.element.find('button').on('click',(e)=>{
      this.element.find('.aside-list').slideToggle();
      this.element.toggleClass('aside-open');
    });
  }
}

window.pew.addRegistryEntry({
  key: 'aside-content',
  domSelector: '.aside-content',
  classDef: AsideComponent
});
